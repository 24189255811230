.eventGroupDiv {
    width: 25%;
    padding: 20px 10px;
    border-radius: 50%;
    background-color: #e0e0e0;
    text-align: center;
    font-weight: 600;
    margin: 10px;
    font-size: 14px;
    display: inline-block;
    line-height: 28px;
    max-height: 44px;
    height: 44px;
    overflow: hidden;
    word-break: break-word;
}

.currentSelected {
    border: solid yellow;
}
.eventGroupSelected {
    background-color: #b2ebf2;
}
.eventGroupSelectedAll {
    background-color: #00e676;
}
.eventDiv {
    width: 25%;
    background-color: #e0e0e0;
    font-weight: 600;
    margin: 10px;
    padding: 20px 10px;
    min-width: 80px;
    text-align: center;
    font-size: 14px;
    display: inline-flex;
    max-height: 40px;
    height: 40px;
}

.eventDivText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.eventSelected {
    background-color: #69f0ae;
}

.wrapper {
    display: flex;
}

.wrapper .firstGroup {
    width: 50%;
}

.wrapper .secondGroup {
    width: 50%;
}
.checkboxEvt {
    margin-left: 15px;
    transform: scale(2);
}

.eventDiv .tooltips-text {
    visibility: hidden;
    max-width: 200px;
    word-break: break-word;
    background-color: #555;
    color: yellow;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
    position: absolute;
    margin-top: 60px;
    margin-left: 20px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.eventDiv:hover .tooltips-text {
    visibility: visible;
    display: inline-block;
    opacity: 1;
    transition-delay: 400ms;
}