.errorInfo {
    color: #dc3545;
    font-weight: 800;
}

.warningInfo {
    color: #e87c10;
    font-weight: 800;
}

.summaryInfo {
    font-size: 20px;
    margin: 10px;
}
