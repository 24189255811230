.popup-z-index {
    z-index: 1500 !important;
    position: absolute;
    top: 5px;
    left: 40%;
}

.success-bg {
    background-color: darkgreen !important;
}

.info-bg {
    background-color: darkblue !important;
}

.warning-bg {
    background-color: darkorange !important;
}

.error-bg {
    background-color: darkred !important;
}

.popup-text {
    color: white !important;
}
