.popup-z-index {
    z-index: 1500 !important;
}

.success-bg {
    background-color: darkgreen !important;
}

.info-bg {
    background-color: darkblue !important;
}

.warning-bg {
    background-color: darkorange !important;
}

.error-bg {
    background-color: darkred !important;
}

.popup-text {
    color: white !important;
}

#tblPopup {
    margin-top: 15px;
    border-collapse: collapse;
    width: 100%;
}

#tblPopup td, #tblPopup th {
    border: 1px solid #ddd;
    padding: 8px;
}

#tblPopup tr:nth-child(even){background-color: #f2f2f2;}

#tblPopup tr:hover {background-color: #ddd;}

#tblPopup th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}
